[data-title] {
	font-size: 18px;
	position: relative;
	cursor: help;
}

[data-title]:hover::before {
	content: attr(data-title);
	position: absolute;
	bottom: -25px;
	padding: 10px;
	background: #000;
	color: #fff;
	font-size: 14px;
	white-space: nowrap;
}

.user-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 120px;
  min-width: 120px;
  margin: 10px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: inherit;
  }

  .user-icon {
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    transition: all ease 0.2s;
    display: flex;
  }

  &:hover {
    .user-icon {
      transform: scale(1.3);
    }
    .first-name {
      opacity: 0;
    }
  }
};
