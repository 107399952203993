.login-screen {
  background-image: url("motorcycle.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.login-container {
  margin: auto;
  /* background-color: rgba(126, 123, 215, 0.2); */
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 500px;
  padding: 50px;
}

.logo {
  position: absolute;
  top: 30px;
  left: 100px;
  width: 200px;
  zIndex: 999;
}

.input-container {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  cursor: text;
}

.input-container > input {
/* .input-container > input, */
/* input:hover, */
/* input:focus, */
/* input:active, */
/* input:-webkit-autofill, */
/* input:-webkit-autofill:hover,  */
/* input:-webkit-autofill:focus, */
/* input:-webkit-autofill:active, */
/* textarea:-webkit-autofill, */
/* textarea:-webkit-autofill:hover, */
/* textarea:-webkit-autofill:focus, */
/* textarea:-webkit-autofill:active, */
/* select:-webkit-autofill, */
/* select:-webkit-autofill:hover, */
/* select:-webkit-autofill:focus, */
/* select:-webkit-autofill:active, { */
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: white;
  margin-left: 2.5rem;
  width: calc(100% - 2.5rem);
  outline: 0;
  border: none;
  border-bottom: 1px solid #ced4da;
  background-color: transparent !important;
  box-shadow: none;
  border-radius: 0;
  padding: .3rem 0 .55rem;
  color: white;
}

.input-container > label {
  margin-left: 2.5rem;
  color: white;
  position: absolute;
  left: 0;
  transition: all 0.3s ease;
  top: -1rem;
}

.input-container > .pristine {
  top: .65rem;
}

.input-container > .icon {
  position: absolute;
  top: 0.65rem;
  left: 0.65rem;
}

.error {
  color: red;
}
