.services {
  display: flex;
  height: 91vh;

  .filter {
    margin:  10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    span {
      margin-right: 10px;
    }

    .delete {
      position: absolute;
      bottom: -7px;
      right: -8px;
    }

    select {
      min-height: 30px;
    }
  }

  &.list {
    width: 50vw;
    display: flex;
    flex-direction: column;
    position: relative;

    &.filters {
      height: 70px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &.users {
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &.table {
      overflow-x: hidden;
      overflow-y: scroll;
      position: relative;
    }

    .overlay {
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .services-map {
    width: 50vw;
    display: flex;
  }
}

.spinner {
   position: absolute;
   left: 50%;
   top: 50%;
   height:60px;
   width:60px;
   margin:0px auto;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(0,174,239,.8);
   border-radius:100%;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}
